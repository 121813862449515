import React, {useContext, useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import {getToken} from "../utils/authentication";
import {Role} from "../types";
import { UserContext } from "../context/UserContext";
import {useToast} from "../context/ToastContext";

interface CreateEditWorkflowProps {
    action: "create" | "edit";
}

interface WorkflowFormValues {
    title: string;
    context: string;
    action: string;
    visible_for_creator: boolean;
    visible_for_converter: boolean;
    shared_with_tenant: boolean;
    is_template: boolean | null;
    is_disabled: boolean;
}

const WorkflowCreateEdit: React.FC<CreateEditWorkflowProps> = ({action}) => {
    const navigate = useNavigate();
    const {id} = useParams<{ id: string }>();
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm<WorkflowFormValues>();
    const [loading, setLoading] = useState<boolean>(false);
    const { currentUser } = useContext(UserContext);
    const {setToast} = useToast();

    useEffect(() => {
        const fetchWorkflow = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + `/workflows/${id}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    setValue("title", data.title);
                    setValue("context", data.context);
                    setValue("action", data.action);
                    setValue("visible_for_creator", data.visible_for_creator);
                    setValue("visible_for_converter", data.visible_for_converter);
                    setValue("shared_with_tenant", data.shared_with_tenant);
                    setValue("is_template", data.is_template);
                    setValue("is_disabled", data.is_disabled);
                } else {
                    const data = await response.json();
                    setToast({show: true, type: "error", message: data.detail || "Ein Fehler ist aufgetreten."});
                }
            } catch (error) {
                setToast({show: true, type: "error", message: "Ein Fehler ist aufgetreten."});
            } finally {
                setLoading(false);
            }
        };

        if (action === "edit" && id) {
            fetchWorkflow();
        }
    }, [action, id, setValue, setToast]);

    const onSubmit: SubmitHandler<WorkflowFormValues> = async (data) => {
        setLoading(true);

        try {
            const method = action === "create" ? "POST" : "PUT";
            const url =
                action === "create"
                    ? process.env.REACT_APP_API_URL + "/workflows"
                    : process.env.REACT_APP_API_URL + `/workflows/${id}`;

            const response = await fetch(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + getToken(),
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setToast({
                    show: true,
                    type: "success",
                    message: action === "create"
                        ? "Workflow erfolgreich erstellt!"
                        : "Workflow erfolgreich aktualisiert!",
                });
                navigate("/workflows");
            } else {
                const data = await response.json();
                setToast({
                    show: true,
                    type: "error",
                    message: data.detail || "Ein Fehler ist aufgetreten.",
                });
            }
        } catch (error) {
            setToast({show: true, type: "error", message: "Ein Fehler ist aufgetreten."});
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);

        const confirmDelete = window.confirm(
            "Soll der Workflow wirklich gelöscht werden?",
        );
        if (!confirmDelete) {
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + `/workflows/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer ' + getToken(),
                    },
                }
            );

            if (response.ok) {
                setToast({show: true, type: "success", message: "Workflow erfolgreich gelöscht!"});
                navigate("/workflows");
            } else {
                const data = await response.json();
                setToast({
                    show: true,
                    type: "error",
                    message: data.detail || "Ein Fehler ist aufgetreten.",
                });
            }
        } catch (error) {
            setToast({show: true, type: "error", message: "Ein Fehler ist aufgetreten."});
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="pb-14">
            <div className="flex justify-center mx-auto">
                <Link to="/workflows" className="inline-block">
                    <button className="flex mt-4 mb-8 rounded-md text-sm font-medium leading-6 text-gray-500">
                        <ChevronLeftIcon className="h-6 w-6 items-center mr-1"/>
                        zurück
                    </button>
                </Link>
            </div>

            <form
                className="border-b rounded-xl bg-white p-5"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="mb-4">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">Titel</label>
                    <div className="mt-2">
                        <input
                            id="title"
                            {...register("title", {required: true})}
                            type="text"
                            className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        />
                        {errors.title && (
                            <p className="mt-2 text-red-500 text-sm">Dieses Feld ist erforderlich.</p>
                        )}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="context" className="block text-sm font-medium leading-6 text-gray-900">Kontext</label>
                    <div className="mt-2">
                        <textarea
                            id="context"
                            {...register("context", {required: true})}
                            rows={5}
                            aria-describedby="context-description"
                            className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        />
                        {errors.context && (
                            <p className="mt-2 text-red-500 text-sm">Dieses Feld ist erforderlich.</p>
                        )}
                    </div>
                    <p id="context-description" className="mt-2 text-sm text-gray-500">
                        Welcher Kontext ist für die AI zusätzlich nötig?
                    </p>
                </div>

                <div className="mb-6">
                    <label htmlFor="action" className="block text-sm font-medium leading-6 text-gray-900">Aktion</label>
                    <div className="mt-2">
                        <textarea
                            id="action"
                            {...register("action", {required: true})}
                            rows={5}
                            aria-describedby="action-description"
                            className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        />
                        {errors.action && (
                            <p className="mt-2 text-red-500 text-sm">Dieses Feld ist erforderlich.</p>
                        )}
                    </div>
                    <p id="action-description" className="mt-2 text-sm text-gray-500">
                        Wie soll die Interaktion mit der AI ablaufen?
                    </p>
                </div>

                <div className="my-6">
                    <div className="relative flex ml-2">
                        <div className="flex h-6 items-center">
                            <input
                                id="visible_for_creator"
                                {...register("visible_for_creator")}
                                type="checkbox"
                                className="form-checkbox h-5 w-5 rounded text-blue-600 focus:ring-0 focus:ring-offset-0"
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                            <label htmlFor="visible_for_creator" className="block text-sm font-medium leading-6 text-gray-900">
                                Sichtbar bei Content Creator
                            </label>
                        </div>
                    </div>
                </div>

                <div className="my-6">
                    <div className="relative flex ml-2">
                        <div className="flex h-6 items-center">
                            <input
                                id="visible_for_converter"
                                {...register("visible_for_converter")}
                                type="checkbox"
                                className="form-checkbox h-5 w-5 rounded text-blue-600 focus:ring-0 focus:ring-offset-0"
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                            <label htmlFor="visible_for_converter" className="block text-sm font-medium leading-6 text-gray-900">
                                Sichtbar bei Content Converter
                            </label>
                        </div>
                    </div>
                </div>

                <div className="my-6">
                    <div className="relative flex ml-2">
                        <div className="flex h-6 items-center">
                            <input
                                id="shared_with_tenant"
                                {...register("shared_with_tenant")}
                                type="checkbox"
                                className="form-checkbox h-5 w-5 rounded text-blue-600 focus:ring-0 focus:ring-offset-0"
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                            <label htmlFor="shared_with_tenant" className="block text-sm font-medium leading-6 text-gray-900">
                                Mit allen Benutzer:innen des Tenants <span
                                className="mx-1 inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-700">{currentUser?.tenant.name}</span> teilen
                            </label>
                        </div>
                    </div>
                </div>

                {currentUser?.role === Role.SuperAdmin && (
                    <div className="my-6">
                        <div className="relative flex ml-2">
                            <div className="flex h-6 items-center">
                                <input
                                    id="is_template"
                                    {...register("is_template")}
                                    type="checkbox"
                                    className="form-checkbox h-5 w-5 rounded text-blue-600 focus:ring-0 focus:ring-offset-0"
                                />
                            </div>
                            <div className="ml-3 text-sm leading-6 flex">
                                <label htmlFor="is_template" className="block text-sm font-medium leading-6 text-gray-900">
                                    Template Workflow
                                </label>
                                <span
                                    className="ml-3 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium border border-blue-700/20 bg-blue-50 text-blue-700">
                                    Super Admin Privileg
                                </span>
                            </div>
                        </div>
                    </div>
                )}

                <div className="my-6">
                    <div className="relative flex ml-2">
                        <div className="flex h-6 items-center">
                            <input
                                id="is_disabled"
                                {...register("is_disabled")}
                                type="checkbox"
                                className="form-checkbox h-5 w-5 rounded text-blue-600 focus:ring-0 focus:ring-offset-0"
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6 flex">
                            <label htmlFor="is_disabled" className="block text-sm font-medium leading-6 text-gray-900">
                                Workflow deaktivieren
                            </label>
                        </div>
                    </div>
                </div>

                <div>
                    <button
                        type="submit"
                        className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-9 items-center ${loading ? 'opacity-60 cursor-not-allowed' : ''}`}
                    >
                        {loading ? (
                            <div className="flex space-x-5">
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            </div>
                        ) : (
                            action === "create" ? "erstellen" : "aktualisieren"
                        )}
                    </button>
                </div>
            </form>

            {action === "edit" && (
                <div className="mt-8">
                    <button
                        type="button"
                        onClick={handleDelete}
                        className="flex justify-center mx-auto rounded-md text-sm font-medium leading-6 text-red-600"
                    >
                        Workflow löschen
                    </button>
                </div>
            )}
        </div>
    );
};

export default WorkflowCreateEdit;
