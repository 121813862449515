import React, { useState, forwardRef, useImperativeHandle } from "react";
import Editor from "react-simple-wysiwyg";

type ContentBlock = {
  type: string;
  text: string;
};

type Phase = {
  content_blocks: ContentBlock[];
};

type ContentUnit = {
  title: string;
  introduction_phase: Phase;
  knowledge_phases: Phase[];
  summary_phase: Phase;
};

type ExportFormProps = {
  initialData: ContentUnit;
};

export interface ExportFormHandle {
  getFormData: () => ContentUnit;
}

const blockTypes = [
  "COPY",
  "HEADLINE",
  "REMEMBER",
  "DEFINITION",
  "IMPORTANT",
  "HINT",
  "EXAMPLE",
  "QUOTE",
];

const backgroundColorMap: Record<string, string> = {
  COPY: "bg-gray-100",
  HEADLINE: "bg-gray-100",
  REMEMBER: "bg-blue-100",
  DEFINITION: "bg-violet-100",
  IMPORTANT: "bg-violet-100",
  HINT: "bg-green-100",
  EXAMPLE: "bg-amber-100",
  QUOTE: "bg-gray-200",
};

const ExportForm = forwardRef<ExportFormHandle, ExportFormProps>(
  ({ initialData }, ref) => {
    const [formData, setFormData] = useState<ContentUnit>(initialData);

    useImperativeHandle(ref, () => ({
      getFormData: () => formData,
    }));

    const handleChange = (
      phaseType: keyof ContentUnit,
      phaseIndex: number | null,
      blockIndex: number,
      field: keyof ContentBlock,
      value: string
    ) => {
      const updated = { ...formData };
      if (phaseType === "knowledge_phases" && phaseIndex !== null) {
        updated[phaseType][phaseIndex].content_blocks[blockIndex][field] = value;
      } else {
        (updated[phaseType] as Phase).content_blocks[blockIndex][field] = value;
      }
      setFormData(updated);
    };

    const handleDeleteBlock = (
      phaseType: keyof ContentUnit,
      phaseIndex: number | null,
      blockIndex: number
    ) => {
      const updated = { ...formData };
      if (phaseType === "knowledge_phases" && phaseIndex !== null) {
        updated[phaseType][phaseIndex].content_blocks.splice(blockIndex, 1);
      } else {
        (updated[phaseType] as Phase).content_blocks.splice(blockIndex, 1);
      }
      setFormData(updated);
    };

    const renderBlock = (
      block: ContentBlock,
      phaseType: keyof ContentUnit,
      phaseIndex: number | null,
      blockIndex: number
    ) => (
      <div
        key={`${phaseType}-${phaseIndex ?? "none"}-${blockIndex}`}
        className="mb-4 flex flex-col gap-1"
      >
        <div className="flex justify-between w-full mt-2 mb-1">
          <select
            value={block.type}
            onChange={(e) =>
              handleChange(
                phaseType,
                phaseIndex,
                blockIndex,
                "type",
                e.target.value
              )
            }
            className="w-40 text-gray-600 text-sm px-3 py-1 border-0 border-gray-300 border-l-2 outline-none focus:outline-none focus:ring-0"
          >
            {blockTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <button
            type="button"
            onClick={() => handleDeleteBlock(phaseType, phaseIndex, blockIndex)}
            className="text-red-500 font-bold mx-2"
            title="Block löschen"
          >
            ✕
          </button>
        </div>
        <Editor
          value={block.text}
          onChange={(e) =>
            handleChange(phaseType, phaseIndex, blockIndex, "text", e.target.value)
          }
          containerProps={{
            className: `w-full p-3 border border-gray-300 border-l-2 resize-none overflow-hidden outline-none focus:outline-none focus:ring-0 ${
              backgroundColorMap[block.type] || "bg-white"
            }`,
          }}
        />
      </div>
    );

    return (
      <form className="space-y-10">
        <div>
          <h2 className="text-lg font-semibold leading-6 text-gray-900 mt-1 mb-3">
            Einleitung
          </h2>
          {formData.introduction_phase.content_blocks.map((block, index) =>
            renderBlock(block, "introduction_phase", null, index)
          )}
        </div>

        {formData.knowledge_phases.map((phase, phaseIndex) => (
          <div key={`phase-${phaseIndex}`}>
            <h2 className="text-lg font-semibold leading-6 text-gray-900 mt-1 mb-3">
              Wissensphase {phaseIndex + 1}
            </h2>
            {phase.content_blocks.map((block, blockIndex) =>
              renderBlock(block, "knowledge_phases", phaseIndex, blockIndex)
            )}
          </div>
        ))}

        <div>
          <h2 className="text-lg font-semibold leading-6 text-gray-900 mt-1 mb-3">
            Zusammenfassung
          </h2>
          {formData.summary_phase.content_blocks.map((block, index) =>
            renderBlock(block, "summary_phase", null, index)
          )}
        </div>
      </form>
    );
  }
);

export default ExportForm;
