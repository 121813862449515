import React, { useEffect } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";

type NotificationType = "success" | "error";

interface ToastProps {
    show: boolean;
    onClose: () => void;
    message: string;
    url?: string;
    urlLabel?: string;
    type?: NotificationType;
}

const icons = {
    success: <CheckCircleIcon className="h-7 w-7 text-green-600" />,
    error: <XCircleIcon className="h-7 w-7 text-red-600" />,
};

const styles = {
    success: {
        container: "bg-green-50 ring-black/5",
        text: "text-green-600",
    },
    error: {
        container: "bg-red-50 ring-black/5",
        text: "text-red-600",
    },
};

const Toast: React.FC<ToastProps> = ({
    show,
    onClose,
    message,
    url,
    urlLabel,
    type = "success",
}) => {
    const style = styles[type];

    useEffect(() => {
        if (!show) return;

        const timeout = setTimeout(() => {
            onClose();
        }, 10000);

        return () => clearTimeout(timeout);
    }, [show, onClose]);

    return (
        <div
            aria-live="assertive"
            className="pointer-events-none fixed top-0 right-0 flex items-end p-4 z-50"
        >
            <div className="flex flex-col items-end">
                <Transition
                    show={show}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0"
                    enterTo="translate-y-0 opacity-100"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className={`pointer-events-auto w-96 max-w-sm overflow-hidden rounded-lg shadow-sm ring-1 ${style.container}`}>
                        <div className="p-3">
                            <div className="flex items-start">
                                <div className="shrink-0">{icons[type]}</div>
                                <div className="ml-3 w-0 flex-1 my-auto">
                                    <p className="text-sm font-medium text-gray-900">{message}</p>
                                    {url && urlLabel && (
                                        <p className="mt-2 text-sm text-gray-500">
                                            <a
                                                href={url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="font-medium text-blue-600 hover:underline"
                                            >
                                                {urlLabel}
                                            </a>
                                        </p>
                                    )}
                                </div>
                                <div className="ml-4 flex shrink-0">
                                    <button
                                        onClick={onClose}
                                        className="inline-flex rounded-md text-gray-400 hover:text-gray-500"
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
};

export default Toast;
