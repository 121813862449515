import React, { useState, useEffect, useCallback } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { useToast } from "../context/ToastContext";
import { getToken } from "../utils/authentication";
import { MilesApiKey } from "../types";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

interface Step {
    name: string;
}

const steps: Step[] = [
    { name: "Content Typ auswählen" },
    { name: "Relevanten Inhalt auswählen" },
    { name: "Inhalte überprüfen" },
    { name: "Daten eingeben" },
    { name: "Export abschließen" },
];

interface ExportWizardProps {
    hasSelectedMessage: boolean;
    onSelectionComplete: (contentType: string) => Promise<void> | void;
    onExportComplete: (title: string, milesApiKey: string) => Promise<void> | void;
    onExportCancel: () => void;
    initialTitle?: string;
}

const ExportWizard: React.FC<ExportWizardProps> = ({
    hasSelectedMessage,
    onSelectionComplete,
    onExportComplete,
    onExportCancel,
    initialTitle,
}) => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [contentType, setContentType] = useState<string>("Lerneinheit");
    const [exportData, setExportData] = useState<{
        title: string;
        milesApiKey: string;
        tag: string;
    }>({ title: initialTitle || "abc", milesApiKey: "", tag: "" });
    const [apiKeys, setApiKeys] = useState<MilesApiKey[]>([]);
    const [stepError, setStepError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { setToast } = useToast();

    const isStep4Valid =
        exportData.title.trim() !== "" && exportData.milesApiKey.trim() !== "";

    const goNext = () => {
        if (currentStep === 1 && !contentType.trim()) {
            setStepError("Content Typ ist ein Pflichtfeld.");
            return;
        }
        if (currentStep === 2 && !hasSelectedMessage) {
            return;
        }
        if (currentStep === 4 && !isStep4Valid) {
            setStepError("Bitte füllen Sie alle Pflichtfelder aus.");
            return;
        } else {
            setStepError("");
        }
        if (currentStep < steps.length) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleSelectionComplete = async () => {
        setLoading(true);
        await onSelectionComplete(contentType);
        setLoading(false);
        goNext();
    };

    const handleExportComplete = async () => {
        setLoading(true);
        await onExportComplete(exportData.title, exportData.milesApiKey);
        setLoading(false);
    };

    const fetchApiKeys = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/miles-api-keys/all`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );
            if (response.ok) {
                const data = await response.json();
                setApiKeys(data);
            } else {
                const errorData = await response.json();
                setToast({
                    show: true,
                    message: errorData.detail || "Ein Fehler ist aufgetreten.",
                    type: "error",
                });
            }
        } catch (error) {
            setToast({
                show: true,
                message: "Ein Fehler ist aufgetreten.",
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    }, [setToast]);

    useEffect(() => {
        fetchApiKeys();
    }, [fetchApiKeys]);

    useEffect(() => {
        if (initialTitle) {
            setExportData((prev) => ({ ...prev, title: initialTitle }));
        }
    }, [initialTitle]);

    return (
        <div className="relative h-full py-8 px-10">
            <h2 className="text-lg font-semibold leading-6 text-gray-900 mt-12 mb-8">
                Export Wizard
            </h2>
            <nav aria-label="Progress">
                <ol role="list" className="relative">
                    {steps.map((step, stepIdx) => {
                        let status: "complete" | "current" | "upcoming";
                        if (stepIdx < currentStep - 1) {
                            status = "complete";
                        } else if (stepIdx === currentStep - 1) {
                            status = "current";
                        } else {
                            status = "upcoming";
                        }
                        return (
                            <li
                                key={step.name}
                                className={classNames(
                                    stepIdx !== steps.length - 1 ? "pb-8" : "",
                                    "relative"
                                )}
                            >
                                {status === "complete" ? (
                                    <>
                                        {stepIdx !== steps.length - 1 && (
                                            <div
                                                aria-hidden="true"
                                                className="absolute left-3.5 top-3.5 -ml-px mt-0.5 h-full w-0.5 bg-blue-600"
                                            />
                                        )}
                                        <div className="group relative flex items-center">
                                            <span className="flex h-7 w-7 items-center justify-center">
                                                <span className="relative z-10 flex h-7 w-7 items-center justify-center rounded-full bg-blue-600">
                                                    <CheckIcon
                                                        aria-hidden="true"
                                                        className="h-4 w-4 text-white"
                                                    />
                                                </span>
                                            </span>
                                            <span className="ml-3 text-sm font-medium">
                                                {step.name}
                                            </span>
                                        </div>
                                    </>
                                ) : status === "current" ? (
                                    <>
                                        {stepIdx !== steps.length - 1 && (
                                            <div
                                                aria-hidden="true"
                                                className="absolute left-3.5 top-3.5 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                                            />
                                        )}
                                        <div className="group relative flex items-center" aria-current="step">
                                            <span className="flex h-7 w-7 items-center justify-center">
                                                <span className="relative z-10 flex h-7 w-7 items-center justify-center rounded-full border-2 border-blue-600 bg-white">
                                                    <span className="h-2.5 w-2.5 rounded-full bg-blue-600" />
                                                </span>
                                            </span>
                                            <span className="ml-3 text-sm font-medium text-blue-600">
                                                {step.name}
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {stepIdx !== steps.length - 1 && (
                                            <div
                                                aria-hidden="true"
                                                className="absolute left-3.5 top-3.5 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                                            />
                                        )}
                                        <div className="group relative flex items-center">
                                            <span className="flex h-7 w-7 items-center justify-center">
                                                <span className="relative z-10 flex h-7 w-7 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                    <span className="h-2.5 w-2.5 rounded-full bg-transparent" />
                                                </span>
                                            </span>
                                            <span className="ml-3 text-sm font-medium text-gray-500">
                                                {step.name}
                                            </span>
                                        </div>
                                    </>
                                )}
                            </li>
                        );
                    })}
                </ol>
            </nav>

            <hr className="my-12 w-1/3 mx-auto" />

            <div>
                {currentStep === 1 && (
                    <div>
                        <label className="block text-sm font-medium text-gray-900">
                            Content Typ auswählen
                        </label>
                        <select
                            value={contentType}
                            onChange={(e) => {
                                setContentType(e.target.value);
                                if (e.target.value.trim()) {
                                    setStepError("");
                                }
                            }}
                            className="mt-4 block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        >
                            <option value="Lerneinheit">Lerneinheit</option>
                        </select>
                        <button
                            onClick={goNext}
                            disabled={!contentType.trim() || loading}
                            className={`mt-8 flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm h-10 ${
                                !contentType.trim()
                                    ? "bg-gray-400 cursor-not-allowed"
                                    : "bg-blue-600 hover:bg-blue-500"
                            } ${loading ? "opacity-60" : ""}`}
                        >
                            weiter
                        </button>
                        {stepError && (
                            <p className="mt-4 text-red-500 text-sm">{stepError}</p>
                        )}
                    </div>
                )}

                {currentStep === 2 && (
                    <div>
                        <p className="mb-4 text-sm leading-6">
                            Wähle aus der Konversation jenen Inhalt aus, der als <b>{contentType}</b> exportiert werden soll.
                        </p>
                        <button
                            onClick={handleSelectionComplete}
                            disabled={!hasSelectedMessage || loading}
                            className={`mt-8 flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm h-10 ${
                                !hasSelectedMessage
                                    ? "bg-gray-400 cursor-not-allowed"
                                    : "bg-blue-600 hover:bg-blue-500"
                            } ${loading ? "opacity-60 cursor-not-allowed" : ""}`}
                        >
                            {loading ? (
                                <div className="flex space-x-5 m-auto">
                                    <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                    <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                    <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                </div>
                            ) : (
                                "Auswahl abgeschlossen"
                            )}
                        </button>
                    </div>
                )}

                {currentStep === 3 && (
                    <div>
                        <p className="mb-4 text-sm leading-6">
                            Überprüfe die Strukturierung der Inhalte und führe Verbesserungen durch, falls notwendig.
                        </p>
                        <button
                            onClick={goNext}
                            className="mt-8 flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 h-10"
                        >
                            Prüfung abgeschlossen
                        </button>
                    </div>
                )}

                {currentStep === 4 && (
                    <div>
                        <div>
                            <label className="block text-sm font-medium text-gray-900">
                                Titel der Lerneinheit
                            </label>
                            <input
                                type="text"
                                required
                                value={exportData.title}
                                onChange={(e) =>
                                    setExportData({ ...exportData, title: e.target.value })
                                }
                                className="mt-4 block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-900">
                                Exportieren für (API Key)
                            </label>
                            <select
                                required
                                value={exportData.milesApiKey}
                                onChange={(e) =>
                                    setExportData({ ...exportData, milesApiKey: e.target.value })
                                }
                                className="mt-4 block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                            >
                                <option value="">Bitte auswählen</option>
                                {apiKeys.map((key) => (
                                    <option key={key.id} value={key.id}>
                                        {key.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button
                            onClick={goNext}
                            disabled={!isStep4Valid || loading}
                            className={`mt-8 flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm h-10 ${
                                !isStep4Valid
                                    ? "bg-gray-400 cursor-not-allowed"
                                    : "bg-blue-600 hover:bg-blue-500"
                            } ${loading ? "opacity-60" : ""}`}
                        >
                            weiter
                        </button>
                        {stepError && (
                            <p className="mt-8 text-red-500 text-sm">{stepError}</p>
                        )}
                    </div>
                )}

                {currentStep === 5 && (
                    <div>
                        <p className="mb-4 text-sm leading-6">
                            Die {contentType} <b>{exportData.title}</b> wird nun im Namen des API-Users nach MILES exportiert.
                        </p>
                        <button
                            onClick={handleExportComplete}
                            disabled={loading}
                            className={`mt-8 flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm h-10 ${
                                !hasSelectedMessage
                                    ? "bg-gray-400 cursor-not-allowed"
                                    : "bg-blue-600 hover:bg-blue-500"
                            } ${loading ? "opacity-60 cursor-not-allowed" : ""}`}
                        >
                            {loading ? (
                                <div className="flex space-x-5 m-auto">
                                    <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                    <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                    <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                </div>
                            ) : (
                                "Export abschließen"
                            )}
                        </button>
                    </div>
                )}
            </div>
            <button
                onClick={onExportCancel}
                className="mt-14 flex w-full justify-center rounded-md bg-gray-100 px-3 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-200 h-10"
            >
                Export abbrechen
            </button>
        </div>
    );
};

export default ExportWizard;
