import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/16/solid";
import { getCurrentUser, getToken, saveUser } from "../utils/authentication";
import { Role } from "../types";
import { UserContext } from "../context/UserContext";
import { useToast } from "../context/ToastContext";

interface TenantFormValues {
    name: string;
    tenant_prompt: string;
    supports_miles_export: boolean;
}

interface TenantCreateEditProps {
    action: "create" | "edit";
}

const TenantCreateEdit: React.FC<TenantCreateEditProps> = ({ action }) => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const { setToast } = useToast();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<TenantFormValues>();

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (action === "edit" && id) {
            const fetchTenant = async () => {
                try {
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}/tenants/${id}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + getToken(),
                            },
                        }
                    );

                    if (response.ok) {
                        const data = await response.json();
                        setValue("name", data.name);
                        setValue("tenant_prompt", data.tenant_prompt);
                        setValue("supports_miles_export", data.supports_miles_export);
                    } else {
                        const data = await response.json();
                        setToast({
                            show: true,
                            message: data.detail || "Ein Fehler ist aufgetreten.",
                            type: "error",
                        });
                    }
                } catch (error) {
                    setToast({
                        show: true,
                        message: "Fehler beim Laden des Tenants.",
                        type: "error",
                    });
                }
            };

            fetchTenant();
        }
    }, [action, id, setValue, setToast]);

    const onSubmit: SubmitHandler<TenantFormValues> = async (data) => {
        setLoading(true);

        try {
            const method = action === "create" ? "POST" : "PUT";
            const url =
                action === "create"
                    ? `${process.env.REACT_APP_API_URL}/tenants`
                    : `${process.env.REACT_APP_API_URL}/tenants/${id}`;

            const response = await fetch(url, {
                method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getToken(),
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                if (action === "edit") {
                    const updatedUser = await getCurrentUser();
                    saveUser(updatedUser);
                    setCurrentUser(updatedUser);
                }

                setToast({
                    show: true,
                    message:
                        action === "create"
                            ? "Tenant erfolgreich erstellt!"
                            : "Tenant erfolgreich aktualisiert!",
                    type: "success",
                });

                if (currentUser?.role === Role.SuperAdmin) {
                    navigate("/tenants");
                }
            } else {
                const data = await response.json();
                setToast({
                    show: true,
                    message: data.detail || "Ein Fehler ist aufgetreten.",
                    type: "error",
                });
            }
        } catch (error) {
            setToast({
                show: true,
                message: "Ein Fehler ist aufgetreten.",
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="pb-14">
            {currentUser?.role === Role.SuperAdmin ? (
                <div className="flex justify-center mx-auto">
                    <Link to="/tenants" className="inline-block">
                        <button className="flex mt-4 mb-8 rounded-md text-sm font-medium leading-6 text-gray-500">
                            <ChevronLeftIcon className="h-6 w-6 items-center mr-1" />
                            zurück
                        </button>
                    </Link>
                </div>
            ) : (
                <div>
                    <h2 className="text-xl font-bold text-left mb-3 leading-6 text-gray-900">
                        Tenant
                    </h2>
                    <p className="mt-1 mr-4 text-sm font-medium text-left text-gray-500">
                        <span>Einstellungen des Tenants</span>
                        <span className="ml-6 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium border border-blue-700/20 bg-blue-50 text-blue-700">
                            Tenant Admin Privileg
                        </span>
                    </p>
                    <hr className="z-10 mb-8" />
                </div>
            )}

            <form className="border-b rounded-xl bg-white p-5" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                        Name
                    </label>
                    <div className="mt-2">
                        <input
                            id="name"
                            {...register("name", { required: true })}
                            type="text"
                            className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        />
                        {errors.name && (
                            <p className="mt-2 text-red-500 text-sm">Dieses Feld ist erforderlich.</p>
                        )}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="tenant_prompt" className="block text-sm font-medium leading-6 text-gray-900">
                        Tenant Prompt
                    </label>
                    <div className="mt-2">
                        <textarea
                            id="tenant_prompt"
                            {...register("tenant_prompt", { required: true })}
                            rows={16}
                            className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        />
                        {errors.tenant_prompt && (
                            <p className="mt-2 text-red-500 text-sm">Dieses Feld ist erforderlich.</p>
                        )}
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                        Welches Verhalten soll der spezifische Tenant aufweisen?
                    </p>
                </div>

                <div className="my-6">
                    <div className="relative flex ml-2">
                        <div className="flex h-6 items-center">
                            <input
                                id="supports_miles_export"
                                {...register("supports_miles_export")}
                                type="checkbox"
                                className="form-checkbox h-5 w-5 rounded text-blue-600 focus:ring-0 focus:ring-offset-0"
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                            <label htmlFor="supports_miles_export" className="block text-sm font-medium leading-6 text-gray-900">
                                MILES-Export aktivieren
                            </label>
                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    <button
                        type="submit"
                        className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-9 items-center ${loading ? "opacity-60 cursor-not-allowed" : ""
                            }`}
                    >
                        {loading ? (
                            <div className="flex space-x-5">
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            </div>
                        ) : action === "create" ? (
                            "erstellen"
                        ) : (
                            "aktualisieren"
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default TenantCreateEdit;
