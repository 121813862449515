import React, {useCallback, useEffect, useState, useContext} from "react";
import {Link} from "react-router-dom";
import {Role, User} from "../types";
import {UserIcon} from "@heroicons/react/16/solid";
import {getToken} from "../utils/authentication";
import {formatDate, formatDateTime} from "../utils/utils";
import { UserContext } from "../context/UserContext";
import SearchBar from "../components/SearchBar";
import PaginationBar from "../components/PaginationBar";
import {useToast} from "../context/ToastContext";

const Users: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    const { currentUser } = useContext(UserContext);
    const {setToast} = useToast();

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        return () => clearTimeout(timer);
    }, [searchTerm]);

    const fetchUsers = useCallback(async () => {
        setLoading(true);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/users?search=${debouncedSearchTerm}&page=${currentPage}&size=10`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );

            if (response.ok) {
                const data = await response.json();
                setUsers(data.items);
                setTotalPages(data.pages);
            } else {
                const errorData = await response.json();
                setToast({
                    show: true,
                    type: "error",
                    message: errorData.detail || "Ein Fehler ist aufgetreten.",
                });
            }
        } catch (error) {
            setToast({
                show: true,
                type: "error",
                message: "Ein Fehler ist aufgetreten.",
            });
        } finally {
            setLoading(false);
        }
    }, [debouncedSearchTerm, currentPage, setToast]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const handleSearchChange = useCallback((value: string) => {
        setSearchTerm(value);
        setCurrentPage(1);
    }, []);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    return (
        <div className="pb-14">
            <div className="flex justify-between">
                <div>
                    <h2 className="text-xl font-bold text-left mb-3 leading-6 text-gray-900">
                        Benutzer:innen
                    </h2>
                    <p className="mt-1 mr-4 text-sm font-medium text-left text-gray-500">
                        {currentUser?.role === Role.SuperAdmin ? (
                            <>
                                <span className="mr-3">Verwaltung aller Benutzer:innen</span>
                                <span
                                    className="my-2 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium border border-blue-700/20 bg-blue-50 text-blue-700">
                                    Super Admin Privileg
                                </span>
                            </>
                        ) : (
                            <>
                                <span>Verwaltung der Benutzer:innen des Tenants</span>
                                <span
                                    className="ml-6 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium border border-blue-700/20 bg-blue-50 text-blue-700">
                                    Tenant Admin Privileg
                                </span>
                            </>
                        )}
                    </p>
                </div>
                <Link to="/users/create">
                    <button
                        className="flex justify-center items-center ml-auto mt-5 py-2 px-3 mb-2 rounded-xl text-sm text-white font-medium cursor-pointer hover:bg-blue-700 bg-blue-600"
                    >
                        <UserIcon className="w-4 h-4 mr-1.5"/>
                        Benutzer:in erstellen
                    </button>
                </Link>
            </div>

            <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange}/>

            <hr className="z-10 my-6"/>

            {loading ? (
                <div className="animate-ping rounded-full bg-slate-300 h-10 w-10 m-auto"></div>
            ) : users.length > 0 && (
                <div className="overflow-x-auto rounded-xl">
                    <table className="min-w-full divide-y divide-gray-200 bg-white border-separate rounded-xl">
                        <thead>
                        <tr>
                            <th className="px-5 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">ID</th>
                            <th className="px-5 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">Name</th>
                            <th className="px-5 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">E-Mail</th>
                            <th className="px-5 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">Tenant</th>
                            <th className="px-5 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">Rolle</th>
                            <th className="px-5 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">Anmeldung</th>
                            <th className="px-5 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">Erstellt</th>
                            <th className="px-5 py-3 text-right text-xs font-semibold text-gray-900 uppercase tracking-wider">Aktion</th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {users.map((user) => (
                            <tr key={user.id}>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">{user.id}</td>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {user.first_name} {user.last_name}
                                </td>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">{user.tenant?.name}</td>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {user.role === "super_admin" ? (
                                        <span className="rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">SuperAdmin</span>
                                    ) : user.role === "tenant_admin" ? (
                                        <span className="rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700">TenantAdmin</span>
                                    ) : (
                                        <span className="rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">User</span>
                                    )}
                                </td>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {user.last_login ? formatDateTime(user.last_login) : "-"}
                                </td>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {formatDate(user.created_at)}
                                </td>
                                <td className="px-5 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <Link
                                        to={`/users/edit/${user.id}`}
                                        className="text-blue-600 hover:text-blue-900"
                                    >
                                        bearbeiten
                                    </Link>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}

            <PaginationBar
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default Users;
