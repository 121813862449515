import React, { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/16/solid";
import { getToken } from "../utils/authentication";
import { Role } from "../types";
import { UserContext } from "../context/UserContext";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useToast } from "../context/ToastContext";

interface MilesApiKeyFormValues {
    key: string;
    secret: string;
    tenant_url: string;
    is_staging: boolean;
}

const InterfaceCreate: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { register, handleSubmit, formState: { errors } } = useForm<MilesApiKeyFormValues>();
    const [loading, setLoading] = useState<boolean>(false);
    const { currentUser } = useContext(UserContext);
    const [showInstruction, setShowInstruction] = useState(false);
    const { setToast } = useToast();

    const toggleInstruction = () => {
        setShowInstruction((prev) => !prev);
    };

    const onSubmit: SubmitHandler<MilesApiKeyFormValues> = async (data) => {
        setLoading(true);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/miles-api-keys", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getToken(),
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setToast({
                    show: true,
                    message: "API Key erfolgreich hinzugefügt!",
                    type: "success",
                });
                navigate("/interfaces");
            } else {
                const errorData = await response.json();
                setToast({
                    show: true,
                    message: errorData.detail || "Ein Fehler ist aufgetreten.",
                    type: "error",
                });
            }
        } catch (error) {
            setToast({
                show: true,
                message: "Ein Fehler ist aufgetreten.",
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="pb-14">
            {currentUser?.role === Role.SuperAdmin ? (
                <div className="flex justify-center mx-auto">
                    <Link to="/interfaces" className="inline-block">
                        <button className="flex mt-4 mb-8 rounded-md text-sm font-medium leading-6 text-gray-500">
                            <ChevronLeftIcon className="h-6 w-6 items-center mr-1" />
                            zurück
                        </button>
                    </Link>
                </div>
            ) : (
                <div>
                    <h2 className="text-xl font-bold text-left mb-3 leading-6 text-gray-900">
                        Tenant
                    </h2>
                    <p className="mt-1 mr-4 text-sm font-medium text-left text-gray-500">
                        <span>Einstellungen des Tenants</span>
                        <span className="ml-6 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium border border-blue-700/20 bg-blue-50 text-blue-700">
                            Tenant Admin Privileg
                        </span>
                    </p>
                    <hr className="z-10 mb-8" />
                </div>
            )}

            <div className="border-b rounded-xl bg-white p-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label htmlFor="tenant_url" className="block text-sm font-medium leading-6 text-gray-900">
                                Tenant URL
                            </label>
                            <input
                                id="tenant_url"
                                {...register("tenant_url", { required: true })}
                                type="url"
                                placeholder="https://portal.miles-learning.com"
                                className="mt-2 block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                            />
                            {errors.tenant_url && (
                                <p className="text-red-500 text-sm mt-2">Tenant URL ist erforderlich.</p>
                            )}
                        </div>
                        <div className="flex items-start md:mt-10 ml-3">
                            <input
                                id="is_staging"
                                {...register("is_staging")}
                                type="checkbox"
                                className="form-checkbox h-5 w-5 rounded text-blue-600 focus:ring-0 focus:ring-offset-0"
                            />
                            <label htmlFor="is_staging" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                Staging API verwenden
                            </label>
                        </div>
                        <div>
                            <label htmlFor="key" className="block text-sm font-medium leading-6 text-gray-900">
                                ID
                            </label>
                            <input
                                id="key"
                                {...register("key", { required: true })}
                                type="text"
                                className="mt-2 block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                            />
                            {errors.key && <p className="text-red-500 text-sm mt-2">ID ist erforderlich.</p>}
                        </div>
                        <div>
                            <label htmlFor="secret" className="block text-sm font-medium leading-6 text-gray-900">
                                Secret
                            </label>
                            <input
                                id="secret"
                                {...register("secret", { required: true })}
                                type="text"
                                className="mt-2 block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                            />
                            {errors.secret && <p className="text-red-500 text-sm mt-2">Secret ist erforderlich.</p>}
                        </div>
                    </div>

                    <button
                        type="submit"
                        className={`mt-6 flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-9 items-center ${
                            loading ? "opacity-60 cursor-not-allowed" : ""
                        }`}
                    >
                        {loading ? (
                            <div className="flex space-x-5">
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            </div>
                        ) : (
                            "hinzufügen"
                        )}
                    </button>
                </form>
            </div>

            <div className="mt-8">
                <button
                    type="button"
                    onClick={toggleInstruction}
                    className={`flex justify-center items-center mx-auto rounded-md text-sm font-medium leading-6 outline-0 ${
                        showInstruction ? "text-gray-500" : "text-blue-600"
                    }`}
                >
                    <InformationCircleIcon className="h-5 w-5 mr-1.5" />
                    Anleitung zur Erstellung eines API Keys in MILES
                </button>

                {showInstruction && (
                    <div className="mt-6 flex justify-center">
                        <div className="rounded-xl bg-white p-4 w-full max-w-xl">
                            <img
                                src="/assets/miles_api_key_generation.png"
                                alt="API-Key-Generierung"
                                className="w-full rounded-md"
                            />
                            <p className="mt-4 font-medium text-sm text-gray-500">
                                {"MILES > Profil > API-Schlüssel"}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InterfaceCreate;
