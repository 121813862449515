import React, { createContext, useContext, useState } from "react";

export type ToastType = "success" | "error";

export interface ToastData {
    show: boolean;
    message: string;
    type: ToastType;
    url?: string;
    urlLabel?: string;
}

interface ToastContextType {
    toast: ToastData;
    setToast: (toast: ToastData) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) throw new Error("useToast must be used within a ToastProvider");
    return context;
};

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [toast, setToast] = useState<ToastData>({
        show: false,
        message: "",
        type: "success",
    });

    return (
        <ToastContext.Provider value={{ toast, setToast }}>
            {children}
        </ToastContext.Provider>
    );
};
