import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getToken, saveUser } from "../utils/authentication";
import { User } from "../types";
import { UserContext } from "../context/UserContext";
import { useToast } from "../context/ToastContext";

interface AccountFormValues {
    first_name: string;
    last_name: string;
    email: string;
    password: string | null;
    new_password: string | null;
}

const Account: React.FC = () => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<AccountFormValues>();
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(false);
    const { setToast } = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            if (currentUser) {
                setValue("first_name", currentUser.first_name);
                setValue("last_name", currentUser.last_name);
                setValue("email", currentUser.email);
            } else {
                setToast({
                    show: true,
                    message: "Benutzer:in konnte nicht geladen werden.",
                    type: "error",
                });
            }
        };

        fetchUserData();
    }, [currentUser, setValue, setToast]);

    const onSubmit: SubmitHandler<AccountFormValues> = async (data) => {
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/account`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const updatedUser = await response.json();
                saveUser(updatedUser);
                setCurrentUser(updatedUser);

                setToast({
                    show: true,
                    message: "Account erfolgreich aktualisiert!",
                    type: "success",
                });
            } else {
                const data = await response.json();
                setToast({
                    show: true,
                    message: data.detail || "Ein Fehler ist aufgetreten.",
                    type: "error",
                });
            }
        } catch (error) {
            setToast({
                show: true,
                message: "Ein Fehler ist aufgetreten.",
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="pb-14">
            <div>
                <h2 className="text-xl font-bold text-left mb-3 leading-6 text-gray-900">Account</h2>
                <p className="mt-1 mr-4 text-sm font-medium text-left text-gray-500">
                    <span>Verwalte deine Daten</span>
                </p>
                <hr className="z-10 mb-8" />
            </div>

            <form className="border-b rounded-xl bg-white p-5" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                    <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-900">
                        Vorname
                    </label>
                    <div className="mt-2">
                        <input
                            id="first_name"
                            {...register("first_name", { required: true })}
                            type="text"
                            className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        />
                        {errors.first_name && (
                            <p className="mt-2 text-red-500 text-sm">Vorname ist erforderlich.</p>
                        )}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
                        Nachname
                    </label>
                    <div className="mt-2">
                        <input
                            id="last_name"
                            {...register("last_name", { required: true })}
                            type="text"
                            className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        />
                        {errors.last_name && (
                            <p className="mt-2 text-red-500 text-sm">Nachname ist erforderlich.</p>
                        )}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        E-Mail
                    </label>
                    <div className="mt-2">
                        <input
                            id="email"
                            {...register("email", { required: true })}
                            type="email"
                            className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        />
                        {errors.email && (
                            <p className="mt-2 text-red-500 text-sm">E-Mail ist erforderlich.</p>
                        )}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                        Passwort
                    </label>
                    <div className="mt-2">
                        <input
                            id="password"
                            {...register("password")}
                            type="password"
                            className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        />
                        {errors.password && (
                            <p className="mt-2 text-red-500 text-sm">Passwort ist ungültig.</p>
                        )}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="new_password" className="block text-sm font-medium leading-6 text-gray-900">
                        Neues Passwort
                    </label>
                    <div className="mt-2">
                        <input
                            id="new_password"
                            {...register("new_password", { minLength: 8 })}
                            type="password"
                            className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        />
                        {errors.new_password && (
                            <p className="mt-2 text-red-500 text-sm">Passwort muss mindestens 8 Zeichen lang sein.</p>
                        )}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
                        Rolle
                    </label>
                    <input
                        type="text"
                        value={currentUser?.role || ""}
                        readOnly
                        className="block w-full bg-gray-100 rounded-md border-0 mt-2 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                    />
                </div>

                <div className="mt-5">
                    <button
                        type="submit"
                        className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-9 items-center ${loading ? 'opacity-60 cursor-not-allowed' : ''}`}
                    >
                        {loading ? (
                            <div className="flex space-x-5">
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            </div>
                        ) : (
                            "aktualisieren"
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Account;