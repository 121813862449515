import React, {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {useSearchParams} from "react-router-dom";
import {getToken} from "../utils/authentication";

interface PasswordResetFormValues {
    new_password: string;
    confirm_password: string;
}

const PasswordResetConfirm: React.FC = () => {
    const {register, handleSubmit, formState: {errors}, watch, reset} = useForm<PasswordResetFormValues>();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchParams] = useSearchParams();

    const resetToken = searchParams.get("token");
    const onSubmit: SubmitHandler<PasswordResetFormValues> = async (data) => {
        setLoading(true);

        if (!resetToken) {
            setErrorMessage("Link ist ungültig.");
            return;
        }

        if (data.new_password !== data.confirm_password) {
            setErrorMessage("Passwörter stimmen nicht überein.");
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/password-reset/confirm`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    reset_token: resetToken,
                    new_password: data.new_password,
                }),
            });

            if (response.ok) {
                setSuccessMessage("Passwort erfolgreich zurückgesetzt!");
                reset();
            } else {
                const responseData = await response.json();
                setErrorMessage(responseData.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            {successMessage && <div className="mb-4 text-green-500 text-sm">{successMessage}</div>}
            {errorMessage && <div className="mb-4 text-red-500 text-sm">{errorMessage}</div>}

            <div>
                <input
                    type="password"
                    placeholder="Neues Passwort"
                    {...register("new_password", {required: true, minLength: 8})}
                    className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                />
                {errors.new_password &&
                    <p className="my-2 text-red-500 text-sm">Passwort muss mindestens 8 Zeichen lang sein.</p>}
            </div>

            <div>
                <input
                    type="password"
                    placeholder="Passwort bestätigen"
                    {...register("confirm_password", {
                        required: true,
                        validate: value => value === watch('new_password')
                    })}
                    className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                />
                {errors.confirm_password &&
                    <p className="my-2 text-red-500 text-sm">Passwörter stimmen nicht überein.</p>}
            </div>

            <div>
                <button
                    type="submit"
                    className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-9 items-center ${loading ? 'opacity-60 cursor-not-allowed' : ''}`}
                >
                    {loading ? (
                        <div className="flex space-x-5">
                            <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                        </div>
                    ) : (
                        "Passwort zurücksetzen"
                    )}
                </button>
            </div>
        </form>
    );
};

export default PasswordResetConfirm;
