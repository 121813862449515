import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import MessageItem from "../components/MessageItem";
import {
    ArrowUpOnSquareIcon,
    BoltIcon,
    BookmarkIcon,
    CheckCircleIcon,
    ChevronLeftIcon,
    DocumentTextIcon,
    PaperAirplaneIcon
} from "@heroicons/react/16/solid";
import {Message, Role, Workflow} from "../types";
import {v4 as uuidv4} from "uuid";
import {useNavigate} from "react-router-dom";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {getToken} from "../utils/authentication";
import { UserContext } from "../context/UserContext";
import WorkflowBar from "../components/WorkflowBar";
import ChatInput from "../components/ChatInput";
import ChatHeader from "../components/ChatHeader";

const sendMessage = async (
    session_id: string,
    document_context: string,
    messages: { role: string; content: string }[],
) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_API_URL + "/content-converter",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + getToken(),
                    "Cache-Control": "no-cache",
                    Connection: "keep-alive",
                },
                body: JSON.stringify({
                    session_id,
                    document_context,
                    messages,
                }),
            },
        );
        if (response.ok) {
            const data = await response.json();
            console.log("Antwort erhalten:", data);
            return [data.answer, data.sources];
        } else {
            const data = await response.json();
            console.log(data.detail || "Ein Fehler ist aufgetreten.");
        }
    } catch (error) {
        console.error("Fehler beim Abrufen der Dokumente:", error);
    }
};

const archiveConversation = async (
    documentTitle: string,
    messages: { role: string; content: string }[],
) => {
    try {
        const currentDate = new Date().toLocaleString("de-DE");

        const archiveTitle = `Konversation | ${documentTitle} | ${currentDate}`

        const archiveData = {
            title: archiveTitle,
            history: JSON.stringify(messages),
            shared_with_tenant: false,
        };

        const response = await fetch(process.env.REACT_APP_API_URL + "/archives", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + getToken(),
            },
            body: JSON.stringify(archiveData),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Archivierung erfolgreich:", data);
            return data;
        } else {
            const data = await response.json();
            console.log(data.detail || "Ein Fehler ist aufgetreten.");
        }
    } catch (error) {
        console.error("Fehler beim Archivieren der Konversation:", error);
    }
};

const ContentConverter: React.FC = () => {
    const navigate = useNavigate();
    const conversationEndRef = useRef<HTMLDivElement>(null);

    const [sessionId, setSessionId] = useState<string>(uuidv4());
    const [messages, setMessages] = useState<Message[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [userInput, setUserInput] = useState<string>("");
    const [workflows, setWorkflows] = useState<Workflow[]>([]);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const [documentContext, setDocumentContext] = useState<string>("");
    const [documentTitle, setDocumentTitle] = useState<string>("");

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        const fetchWorkflows = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/workflows/all",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    setWorkflows(data);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            } finally {
                setLoading(false);
            }
        };

        fetchWorkflows();
    }, []);

    useEffect(() => {
        const newSessionId = uuidv4();
        setSessionId(newSessionId);
    }, []);

    const handleWorkflow = async (workflow: Workflow) => {
        const newMessages = [
            ...messages,
            {
                role: "system",
                content: workflow.context + "\n \n" + workflow.action,
                sources: [],
            },
        ];

        setMessages(newMessages);
        conversationEndRef.current?.scrollIntoView({behavior: "smooth"});
        setLoading(true);

        const response = await sendMessage(
            sessionId,
            documentContext,
            newMessages,
        );
        handleResponse(response);
    };

    const handleMessage = async () => {
        if (userInput === "") return;

        const newMessages = [
            ...messages,
            {role: "user", content: userInput, sources: []},
        ];

        setMessages(newMessages);
        conversationEndRef.current?.scrollIntoView({behavior: "smooth"});
        setUserInput("");
        setLoading(true);

        const response = await sendMessage(
            sessionId,
            documentContext,
            newMessages,
        );
        handleResponse(response);
    };

    const handleResponse = (response: any) => {
        if (response) {
            setMessages((prevMessages) => [
                ...prevMessages,
                {role: "assistant", content: response[0], sources: response[1]},
            ]);
            conversationEndRef.current?.scrollIntoView({behavior: "smooth"});

            setLoading(false);
        }
        console.log("response", response);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    useEffect(() => {
        if (selectedFile) {
            handleUpload();
        }
    }, [selectedFile]);

    const handleUpload = async () => {
        if (!selectedFile) return;

        setUploading(true);

        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/document-parser`,
                {
                    method: "POST",
                    headers: {
                        "Authorization": 'Bearer ' + getToken(),
                    },
                    body: formData,
                },
            );
            if (response.ok) {
                const data = await response.json();
                // console.log("Document parsed:", data);
                setDocumentContext(data.extracted_text);
                setDocumentTitle(data.file_name);
                setUploadSuccess(true);
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setUploading(false);
            setSelectedFile(null);
        }
    };

    const handleArchiveClick = async () => {
        const archivedData = await archiveConversation(
            documentTitle,
            messages
        );
        if (archivedData) {
            setMessages([]);
            setUserInput("");
            setSessionId(uuidv4());
            navigate("/archives");
        }
    };

    return (
        <>
            <div>
                <h2 className="text-xl font-bold text-left mb-3 leading-6 text-gray-900">
                    Content Converter
                </h2>
                <p className="mt-1 mr-4 text-sm font-medium text-left text-gray-500">
                    <span>Dokumente in Contents konvertieren</span>
                </p>
                <hr className="z-10 mb-8"/>
            </div>
            <div>
                <h2 className="text-lg font-semibold text-center mx-2 mb-4 leading-6 text-gray-900">
                    Dokument wählen
                </h2>
                <p className="mt-1 mb-6 text-sm font-medium text-center text-gray-500">
                    Das Dokument dient als Basis für die Content-Entwicklung
                </p>

                <div className="col-span-full bg-white rounded-2xl p-4 mb-10">
                    {uploading && (
                        <div className="flex row justify-between">
                            <ArrowPathIcon className="animate-spin h-6 w-6 text-blue-600 mr-4"/>
                        </div>
                    )}
                    {documentContext ? (
                        <div className="flex">
                            <CheckCircleIcon
                                className="mr-3 h-5 w-5 text-green-600"
                                aria-hidden="true"
                            />
                            <span
                                className="text-sm font-normal text-green-600">
                                Der Kontext des Dokuments <span className="font-bold">{documentTitle}</span> ist verfügbar.
                            </span>
                        </div>
                    ) : (
                        <div className="flex justify-center rounded-2xl px-6 py-4">
                            <div>
                                <DocumentTextIcon
                                    className="mx-auto h-12 w-12 text-gray-300"
                                    aria-hidden="true"
                                />
                                <div className="flex justify-center mt-4 text-sm leading-6 text-gray-600">
                                    <label
                                        htmlFor={`file-upload`}
                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-600"
                                    >
                                        <span>PDF, DOCX, PPTX oder TXT hochladen</span>
                                        <input
                                            id={`file-upload`}
                                            name="file-upload"
                                            type="file"
                                            className="sr-only"
                                            onChange={handleFileChange}
                                        />
                                    </label>
                                </div>
                                {errorMessage && (
                                    <div className="mt-6 text-red-500 text-sm text-center">{errorMessage}</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <hr className="z-10"/>
            </div>
            <div>
                <ChatHeader
                    title="Konversation"
                    messageCount={messages.length}
                    onArchiveClick={handleArchiveClick}
                    exportEnabled={false}
                    onExportClick={() => alert("Implementierung in Arbeit")}
                />

                <div className="mt-6 pb-72">
                    <div>
                        {messages?.map(
                            (message, i) =>
                                message.role !== "system" && (
                                    <MessageItem {...message} key={i}/>
                                ),
                        )}
                    </div>

                    <div ref={conversationEndRef} className="pt-12 pb-20"></div>
                </div>
            </div>

            <div className="fixed bottom-0 left-0 right-0 z-10 lg:pl-72">
                <div className="px-6 lg:px-8 m-auto w-full max-w-[880px]">
                    <div className="rounded-t-xl py-4 backdrop-blur-md bg-gray-200/75 w-full">
                        <WorkflowBar
                            workflows={workflows}
                            currentUser={currentUser}
                            onWorkflowClick={handleWorkflow}
                            context="converter"
                            defaultExpanded={documentContext !== ""}
                            disabled={documentContext === ""}
                        />

                        <ChatInput
                            userInput={userInput}
                            onInputChange={setUserInput}
                            onSendMessage={handleMessage}
                            loading={loading}
                            disabled={!documentContext}
                            placeholder={!documentContext ? "Dokument wählen …" : "Deine Anweisung …"}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContentConverter;
