import React, { createContext, useState, useEffect, ReactNode } from "react";
import { User } from "../types";
import { getToken, getUser } from "../utils/authentication";

interface UserContextType {
    currentUser: User | undefined;
    setCurrentUser: (user: User | undefined) => void;
    currentUserToken: string;
    setCurrentUserToken: (token: string) => void;
}

export const UserContext = createContext<UserContextType>({
    currentUser: undefined,
    setCurrentUser: () => {},
    currentUserToken: "",
    setCurrentUserToken: () => {}
});

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User>();
    const [currentUserToken, setCurrentUserToken] = useState<string>("");

    useEffect(() => {
        const token = getToken();
        const user = getUser();
        if (token && user) {
            setCurrentUserToken(token);
            setCurrentUser(user);
        }
    }, []);

    return (
        <UserContext.Provider value={{ currentUser, setCurrentUser, currentUserToken, setCurrentUserToken }}>
            {children}
        </UserContext.Provider>
    );
};
