import React, {useContext} from "react";
import ReactMarkdown from "react-markdown";

import "./MessageItem.css";
import {Message} from "../types";
import { UserContext } from "../context/UserContext";

interface MessageItemProps extends Message {
    isSelected?: boolean;
}

const MessageItem: React.FC<MessageItemProps> = ({role, content, sources, isSelected}) => {
    const { currentUser } = useContext(UserContext);

    return (
        <div className={`${role}-message message mt-4`}>
            <div
                className={`flex flex-col w-full leading-1.5 px-4 rounded-xl rounded-es-xl border border-blue-600/30 ${
                    isSelected ? "!text-gray-900 !bg-white !border !border-gray-50 !border-l-2 !border-l-green-600/60" : ""
                }`}
            >
                <ReactMarkdown className="markdown">{content}</ReactMarkdown>
                {sources?.length > 0 && (
                    <div className="w-full flex flex-wrap mt-2">
                        {sources.map((source, i) => (
                            <div
                                key={i}
                                className="mb-4 mr-2 rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-500 ring-1 ring-inset ring-blue-600/20"
                            >
                                {source}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <span className="font-semibold text-sm m-2">
        {role === "assistant"
            ? "AI"
            : role === "user"
                ? currentUser?.first_name
                : role.slice(0, 1).toUpperCase() + role.slice(1)}
      </span>
        </div>
    );
};

export default MessageItem;
