import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import MessageItem from "../components/MessageItem";
import {
    ArrowUpOnSquareIcon,
    BoltIcon,
    BookmarkIcon,
    CloudArrowUpIcon,
    PaperAirplaneIcon,
} from "@heroicons/react/16/solid";
import { Message, Role, Workflow } from "../types";
import KnowledgeBaseItem from "../components/KnowledgeBaseItem";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { getToken } from "../utils/authentication";
import { UserContext } from "../context/UserContext";
import WorkflowBar from "../components/WorkflowBar";
import ChatInput from "../components/ChatInput";
import ChatHeader from "../components/ChatHeader";
import ExportWizard from "../components/ExportWizard";
import ExportForm, { ExportFormHandle } from "../components/ExportForm";
import Toast from "../components/Toast";
import { useToast } from "../context/ToastContext";


const sendMessage = async (
    session_id: string,
    knowledge_base_id: string,
    limit_llm_knowledge: boolean,
    messages: { role: string; content: string }[],
) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_API_URL + "/content-creator",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getToken(),
                    "Cache-Control": "no-cache",
                    Connection: "keep-alive",
                },
                body: JSON.stringify({
                    session_id,
                    knowledge_base_id,
                    messages,
                    limit_llm_knowledge,
                }),
            },
        );
        if (response.ok) {
            const data = await response.json();
            return [data.answer, data.sources];
        } else {
            const data = await response.json();
            console.log(data.detail || "Ein Fehler ist aufgetreten.");
        }
    } catch (error) {
        console.error("Fehler beim Abrufen der Dokumente:", error);
    }
};

const archiveConversation = async (
    selectedKnowledgeBase: any,
    messages: { role: string; content: string }[],
) => {
    try {
        const currentDate = new Date().toLocaleString("de-DE");

        const archiveTitle = selectedKnowledgeBase
            ? `Konversation | ${selectedKnowledgeBase.title} | ${currentDate}`
            : `Konversation | ${currentDate}`;

        const archiveData = {
            title: archiveTitle,
            history: JSON.stringify(messages),
            shared_with_tenant: false,
        };

        const response = await fetch(
            process.env.REACT_APP_API_URL + "/archives",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getToken(),
                },
                body: JSON.stringify(archiveData),
            },
        );

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            const data = await response.json();
            console.log(data.detail || "Ein Fehler ist aufgetreten.");
        }
    } catch (error) {
        console.error("Fehler beim Archivieren der Konversation:", error);
    }
};

const ContentCreator: React.FC = () => {
    const navigate = useNavigate();
    const conversationEndRef = useRef<HTMLDivElement>(null);
    const { currentUser } = useContext(UserContext);

    const [sessionId, setSessionId] = useState<string>(uuidv4());
    const [messages, setMessages] = useState<Message[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [userInput, setUserInput] = useState<string>("");
    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [knowledgeBases, setKnowledgeBases] = useState<any[]>([]);
    const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState<any>(null);
    const [limitLLMKnowledge, setLimitLLMKnowledge] = useState<boolean>(false);
    const [exportMode, setExportMode] = useState<boolean>(false);
    const [exportData, setExportData] = useState<any>(null);
    const [selectedMessage, setSelectedMessage] = useState<number | null>(null);
    const [exportCompleted, setExportCompleted] = useState<boolean>(false);

    const { setToast } = useToast();


    const exportFormRef = useRef<ExportFormHandle>(null);

    useEffect(() => {
        const fetchWorkflows = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/workflows/all",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    setWorkflows(data);
                } else {
                    const data = await response.json();
                    setToast({
                        show: true,
                        message: data.detail || "Ein Fehler ist aufgetreten. (1)",
                        type: "error",
                    });
                }
            } catch (error) {
                setToast({
                    show: true,
                    message: "Ein Fehler ist aufgetreten. (2)",
                    type: "error",
                });
            } finally {
                setLoading(false);
            }
        };

        fetchWorkflows();
    }, []);

    useEffect(() => {
        const fetchKnowledgeBases = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/knowledgebases/all",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    setKnowledgeBases(data);
                } else {
                    const data = await response.json();
                    setToast({
                        show: true,
                        message: data.detail || "Ein Fehler ist aufgetreten. (3)",
                        type: "error",
                    });
                }
            } catch (error) {
                setToast({
                    show: true,
                    message: "Ein Fehler ist aufgetreten. (4)",
                    type: "error",
                });
            } finally {
                setLoading(false);
            }
        };

        fetchKnowledgeBases();
    }, []);

    useEffect(() => {
        setSessionId(uuidv4());
    }, []);

    const handleWorkflow = async (workflow: Workflow) => {
        const newMessages = [
            ...messages,
            {
                role: "system",
                content: workflow.context + "\n\n" + workflow.action,
                sources: [],
            },
        ];
        setMessages(newMessages);
        conversationEndRef.current?.scrollIntoView({ behavior: "smooth" });
        setLoading(true);
        const response = await sendMessage(
            sessionId,
            selectedKnowledgeBase?.id,
            limitLLMKnowledge,
            newMessages,
        );
        handleResponse(response);
    };

    const handleMessage = async () => {
        if (userInput === "") return;
        const newMessages = [
            ...messages,
            { role: "user", content: userInput, sources: [] },
        ];
        setMessages(newMessages);
        setUserInput("");
        setLoading(true);
        const response = await sendMessage(
            sessionId,
            selectedKnowledgeBase?.id,
            limitLLMKnowledge,
            newMessages,
        );
        conversationEndRef.current?.scrollIntoView({ behavior: "smooth" });
        handleResponse(response);
    };

    const handleResponse = (response: any) => {
        if (response) {
            setMessages((prev) => [
                ...prev,
                { role: "assistant", content: response[0], sources: response[1] },
            ]);
            conversationEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }
        setLoading(false);
    };

    const handleArchiveClick = async () => {
        const archivedData = await archiveConversation(selectedKnowledgeBase, messages);
        if (archivedData) {
            setToast({
                show: true,
                message: "Konversation wurde archiviert.",
                type: "success",
            });

            setMessages([]);
            setUserInput("");
            setSessionId(uuidv4());
            navigate("/archives");
        }
    };

    const handleExportPrepare = async (contentType: string) => {
        if (selectedMessage === null) return;
        const message = messages[selectedMessage];
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/export/prepare/content-unit`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken(),
                    },
                    body: JSON.stringify({ content: message.content }),
                },
            );
            if (response.ok) {
                const text = await response.text();
                const data = JSON.parse(text);
                setExportData(data);
                setExportCompleted(true);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.detail);
            }
        } catch (error) {
            setToast({
                show: true,
                message: "Die Struktur des gewählten Inhalts weicht zu sehr vom Content Typ ab.",
                type: "error",
            });
            handleExportCancel();
        }
    };

    const handleExportCancel = () => {
        setExportMode(false);
        setExportCompleted(false);
        setExportData(null);
        setSelectedMessage(null);
    };
    
    const handleExportComplete = async (title: string, milesApiKey: string) => {
        if (!exportFormRef.current) return;
        const formData = exportFormRef.current.getFormData();

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/export/complete/content-unit`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken(),
                    },
                    body: JSON.stringify({
                        title,
                        miles_api_key_id: milesApiKey,
                        content_unit: formData,
                    }),
                },
            );

            if (response.ok) {
                const data = await response.json();
                setToast({
                    show: true,
                    message: "Content Unit wurde erfolgreich exportiert.",
                    type: "success",
                    url: data.url,
                    urlLabel: "in MILES ansehen",
                });
            } else {
                const errData = await response.json();
                setToast({
                    show: true,
                    message: "Export fehlgeschlagen: " + errData.detail,
                    type: "error",
                });
            }
        } catch (error) {
            setToast({
                show: true,
                message: "Export fehlgeschlagen.",
                type: "error",
            });
        } finally {
            handleExportCancel();
        }
    };

    return (
        <div className="relative">
            {!exportMode && !exportCompleted && (
                <>
                    <div>
                        <h2 className="text-xl font-bold text-left mb-3 leading-6 text-gray-900">
                            Content Creator
                        </h2>
                        <p className="mt-1 mr-4 text-sm font-medium text-left text-gray-500">
                            <span>Contents schrittweise entwickeln</span>
                        </p>
                        <hr className="z-10 mb-8"/>
                    </div>
                    <div className="w-full">
                        <h2 className="text-lg font-semibold text-center mx-2 mb-4 leading-6 text-gray-900">
                            Domänenwissen wählen
                        </h2>
                        <p className="mt-1 mb-6 text-sm font-medium text-center text-gray-500">
                            Das ausgewählte Domänenwissen steht der AI zur Verfügung
                        </p>
                        <div className="mt-4 flex row space-x-4">
                            <select
                                id="knowledgeBase"
                                name="knowledgeBase"
                                className="w-full p-3 font-medium text-blue-600 border-b rounded-xl border-r-12 border-white appearance-none bg-none"
                                onChange={(e) => {
                                    const selectedId = e.target.value;
                                    const kb = knowledgeBases.find((k) => k.id === selectedId);
                                    setSelectedKnowledgeBase(kb);
                                }}
                            >
                                <option value="">Knowledge Base auswählen</option>
                                {knowledgeBases.map((kb) => (
                                    <option key={kb.id} value={kb.id}>
                                        {kb.title}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {selectedKnowledgeBase && (
                            <>
                                <KnowledgeBaseItem {...selectedKnowledgeBase} edit_mode={false}/>
                                <fieldset>
                                    <div className="mt-6">
                                        <div className="relative flex justify-center">
                                            <div className="flex h-6 items-center">
                                                <input
                                                    id="limit-llm-knowledge"
                                                    type="checkbox"
                                                    className="form-checkbox h-5 w-5 rounded text-blue-600 border-none focus:ring-0 focus:ring-offset-0"
                                                    onChange={(e) => setLimitLLMKnowledge(e.target.checked)}
                                                />
                                            </div>
                                            <div className="ml-3 text-sm leading-6">
                                                <label
                                                    htmlFor="limit-llm-knowledge"
                                                    className="font-medium text-gray-900"
                                                >
                                                    AI auf Domänenwissen beschränken
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </>
                        )}
                        <hr className="z-10"/>
                    </div>
                </>
            )}

            <div className={exportMode ? "mr-80 xl:mr-72 xl:-ml-8 2xl:mr-48 2xl:-ml-32" : ""}>
                {!exportCompleted ? (
                    <>
                        <ChatHeader
                            title="Konversation"
                            messageCount={messages.length}
                            onArchiveClick={handleArchiveClick}
                            exportEnabled={
                                currentUser?.tenant.supports_miles_export &&
                                !exportMode
                            }
                            onExportClick={() => setExportMode(true)}
                        />

                        <div className="mt-6 pb-72">
                            <div>
                                {messages?.map((message, i) => {
                                    if (message.role === "system") return null;
                                    return (
                                        <div key={i} className="flex justify-between">
                                            <div className="mt-6">
                                                {exportMode && (
                                                    <input
                                                        type="checkbox"
                                                        name="messageSelection"
                                                        value={i}
                                                        className="w-7 h-7 text-green-600 rounded-lg border border-gray-300 mr-4 focus:ring-0 focus:ring-offset-0"
                                                        checked={selectedMessage === i}
                                                        onChange={() => setSelectedMessage(i)}
                                                    />
                                                )}
                                            </div>
                                            <div className="w-full">
                                                <MessageItem {...message} isSelected={selectedMessage === i}/>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div ref={conversationEndRef} className="pt-12 pb-20"></div>
                        </div>
                    </>
                ) : (
                    <div className="p-4 bg-white shadow rounded-xl">
                        {exportData ? (
                            <ExportForm ref={exportFormRef} initialData={exportData} />
                        ) : (
                            <p>Keine Daten vorhanden.</p>
                        )}
                    </div>
                )}
            </div>

            {!exportMode && !exportCompleted && (
                <div className="fixed bottom-0 left-0 right-0 z-10 lg:pl-72">
                    <div className="px-6 lg:px-8 m-auto w-full max-w-[880px]">
                        <div className="rounded-t-xl py-4 backdrop-blur-md bg-gray-200/75 w-full">
                            <WorkflowBar
                                workflows={workflows}
                                currentUser={currentUser}
                                onWorkflowClick={handleWorkflow}
                                context="creator"
                            />
                            <ChatInput
                                userInput={userInput}
                                onInputChange={setUserInput}
                                onSendMessage={handleMessage}
                                loading={loading}
                                disabled={false}
                            />
                        </div>
                    </div>
                </div>
            )}

            {exportMode && (
                <div className="fixed right-0 top-0 h-full w-80 bg-white border-l overflow-y-auto">
                    <ExportWizard
                        hasSelectedMessage={selectedMessage !== null}
                        onSelectionComplete={handleExportPrepare}
                        onExportComplete={handleExportComplete}
                        onExportCancel={handleExportCancel}
                        initialTitle={exportData?.title}
                    />
                </div>
            )}
        </div>
    );
};

export default ContentCreator;
