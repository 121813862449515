import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { KnowledgeBase } from "../types";
import { CircleStackIcon } from "@heroicons/react/16/solid";
import KnowledgeBaseItem from "../components/KnowledgeBaseItem";
import SearchBar from "../components/SearchBar";
import PaginationBar from "../components/PaginationBar";
import { getToken } from "../utils/authentication";
import { useToast } from "../context/ToastContext";

const KnowledgeBases: React.FC = () => {
    const [knowledgeBases, setKnowledgeBases] = useState<KnowledgeBase[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    const { setToast } = useToast();

    // Debounced search
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        return () => clearTimeout(timer);
    }, [searchTerm]);

    const fetchKnowledgeBases = useCallback(async () => {
        setLoading(true);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/knowledgebases?search=${debouncedSearchTerm}&page=${currentPage}&size=10`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );

            if (response.ok) {
                const data = await response.json();
                setKnowledgeBases(data.items);
                setTotalPages(data.pages);
            } else {
                const data = await response.json();
                setToast({
                    show: true,
                    message: data.detail || "Ein Fehler ist aufgetreten.",
                    type: "error",
                });
            }
        } catch (error) {
            setToast({
                show: true,
                message: "Ein Fehler ist aufgetreten.",
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    }, [debouncedSearchTerm, currentPage, setToast]);

    useEffect(() => {
        fetchKnowledgeBases();
    }, [fetchKnowledgeBases]);

    const handleSearchChange = useCallback((value: string) => {
        setSearchTerm(value);
        setCurrentPage(1);
    }, []);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    return (
        <div className="pb-14">
            <div className="flex justify-between mb-4">
                <div>
                    <h2 className="text-xl font-bold text-left mb-2 leading-6 text-gray-900">
                        Knowledge Bases
                    </h2>
                    <p className="mt-1 text-sm font-medium text-left text-gray-500">
                        Domänenwissen für den Content Creator
                    </p>
                </div>
                <Link to="/knowledgebases/create">
                    <button
                        className="flex justify-center items-center ml-auto mt-5 py-2 px-3 mb-2 rounded-xl text-sm text-white font-medium cursor-pointer hover:bg-blue-700 bg-blue-600">
                        <CircleStackIcon className="w-4 h-4 mr-1.5" />
                        Knowledge Base erstellen
                    </button>
                </Link>
            </div>

            <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />

            <hr className="z-10 my-6" />

            {loading ? (
                <div className="animate-ping rounded-full bg-slate-300 h-10 w-10 m-auto"></div>
            ) : (
                knowledgeBases.map((knowledgeBase) => (
                    <KnowledgeBaseItem {...knowledgeBase} key={knowledgeBase.id} edit_mode={true} />
                ))
            )}

            <PaginationBar
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default KnowledgeBases;
