import React, {useContext, useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import {Role} from "../types";
import {getToken} from "../utils/authentication";
import { UserContext } from "../context/UserContext";
import {useToast} from "../context/ToastContext";

interface UserFormValues {
    first_name: string;
    last_name: string;
    email: string;
    tenant_id: string;
    role: Role;
}

interface Tenant {
    id: string;
    name: string;
}

interface UserCreateEditProps {
    action: "create" | "edit";
}

const UserCreateEdit: React.FC<UserCreateEditProps> = ({action}) => {
    const navigate = useNavigate();
    const {id} = useParams<{ id: string }>();
    const {register, handleSubmit, setValue, formState: {errors}} = useForm<UserFormValues>();
    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { currentUser } = useContext(UserContext);
    const {setToast} = useToast();

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/tenants/all`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer ' + getToken(),
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setTenants(data);
                } else {
                    const data = await response.json();
                    setToast({
                        show: true,
                        type: "error",
                        message: data.detail || "Ein Fehler ist aufgetreten.",
                    });
                }
            } catch (error) {
                setToast({
                    show: true,
                    type: "error",
                    message: "Ein Fehler ist aufgetreten.",
                });
            }
        };

        fetchTenants();
    }, [setToast]);

    useEffect(() => {
        if (action === "edit" && id) {
            const fetchUser = async () => {
                try {
                    const response = await fetch(process.env.REACT_APP_API_URL + `/users/${id}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setValue("first_name", data.first_name);
                        setValue("last_name", data.last_name);
                        setValue("email", data.email);
                        setValue("tenant_id", data.tenant_id);
                        setValue("role", data.role);
                    } else {
                        const data = await response.json();
                        setToast({
                            show: true,
                            type: "error",
                            message: data.detail || "Ein Fehler ist aufgetreten.",
                        });
                    }
                } catch (error) {
                    setToast({
                        show: true,
                        type: "error",
                        message: "Ein Fehler ist aufgetreten.",
                    });
                }
            };

            fetchUser();
        }
    }, [action, id, setValue, setToast]);

    const onSubmit: SubmitHandler<UserFormValues> = async (data) => {
        setLoading(true);

        try {
            const method = action === "create" ? "POST" : "PUT";
            const url = action === "create"
                ? process.env.REACT_APP_API_URL + "/users"
                : process.env.REACT_APP_API_URL + `/users/${id}`;

            const response = await fetch(url, {
                method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + getToken(),
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setToast({
                    show: true,
                    type: "success",
                    message: action === "create"
                        ? "Benutzer:in erfolgreich erstellt!"
                        : "Benutzer:in erfolgreich aktualisiert!",
                });
                navigate("/users");
            } else {
                const data = await response.json();
                setToast({
                    show: true,
                    type: "error",
                    message: data.detail || "Ein Fehler ist aufgetreten.",
                });
            }
        } catch (error) {
            setToast({
                show: true,
                type: "error",
                message: "Ein Fehler ist aufgetreten.",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="pb-14">
            <div className="flex justify-center mx-auto">
                <Link to="/users" className="inline-block">
                    <button className="flex mt-4 mb-8 rounded-md text-sm font-medium leading-6 text-gray-500">
                        <ChevronLeftIcon className="h-6 w-6 items-center mr-1"/>
                        zurück
                    </button>
                </Link>
            </div>

            <form className="border-b rounded-xl bg-white p-5" onSubmit={handleSubmit(onSubmit)}>
                {action === "create" && (
                    <span className="mb-4 inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                        Benutzer:in erhält nach Erstellung E-Mail mit Zugangsdaten
                    </span>
                )}

                <div className="mb-4">
                    <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-900">
                        Vorname
                    </label>
                    <div className="mt-2">
                        <input
                            id="first_name"
                            {...register("first_name", {required: true})}
                            type="text"
                            className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        />
                        {errors.first_name && <p className="mt-2 text-red-500 text-sm">Vorname ist erforderlich.</p>}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
                        Nachname
                    </label>
                    <div className="mt-2">
                        <input
                            id="last_name"
                            {...register("last_name", {required: true})}
                            type="text"
                            className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        />
                        {errors.last_name && <p className="mt-2 text-red-500 text-sm">Nachname ist erforderlich.</p>}
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        E-Mail
                    </label>
                    <div className="mt-2">
                        <input
                            id="email"
                            {...register("email", {required: true})}
                            type="email"
                            className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        />
                        {errors.email && <p className="mt-2 text-red-500 text-sm">E-Mail ist erforderlich.</p>}
                    </div>
                </div>

                {currentUser?.role === Role.SuperAdmin && (
                    <>
                        {action === "create" && (
                            <div className="mb-4">
                                <label htmlFor="tenant_id" className="block text-sm font-medium leading-6 text-gray-900">
                                    Tenant
                                </label>
                                <div className="mt-2">
                                    <select
                                        id="tenant_id"
                                        {...register("tenant_id", {required: true})}
                                        className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                                    >
                                        <option value="">Tenant auswählen</option>
                                        {tenants.map((tenant) => (
                                            <option key={tenant.id} value={tenant.id}>
                                                {tenant.name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.tenant_id && (
                                        <p className="mt-2 text-red-500 text-sm">Tenant ist erforderlich.</p>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="mb-4">
                            <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
                                Rolle
                            </label>
                            <div className="mt-2">
                                <select
                                    id="role"
                                    {...register("role", {required: true})}
                                    defaultValue={Role.User}
                                    className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                                >
                                    <option value="">Rolle auswählen</option>
                                    {Object.entries(Role).map(([key, value]) => (
                                        <option key={value} value={value}>
                                            {key}
                                        </option>
                                    ))}
                                </select>
                                {errors.role && (
                                    <p className="mt-2 text-red-500 text-sm">Rolle ist erforderlich.</p>
                                )}
                            </div>
                        </div>
                    </>
                )}

                <div className="mt-5">
                    <button
                        type="submit"
                        className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-9 items-center ${loading ? 'opacity-60 cursor-not-allowed' : ''}`}
                    >
                        {loading ? (
                            <div className="flex space-x-5">
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            </div>
                        ) : (
                            action === "create" ? "erstellen" : "aktualisieren"
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UserCreateEdit;
