import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import ContentCreator from "./pages/ContentCreator";
import KnowledgeBases from "./pages/KnowledgeBases";
import Archives from "./pages/Archives";
import Workflows from "./pages/Workflows";
import Settings from "./pages/Settings";
import WorkflowCreateEdit from "./pages/WorkflowCreateEdit";
import KnowledgeBasesCreateEdit from "./pages/KnowledgeBasesCreateEdit";
import ArchivesEdit from "./pages/ArchivesEdit";
import ContentConverter from "./pages/ContentConverter";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthenticationLayout from "./components/AuthenticationLayout";
import Tenants from "./pages/Tenants";
import TenantCreateEdit from "./pages/TenantCreateEdit";
import Users from "./pages/Users";
import UserCreateEdit from "./pages/UserCreateEdit";
import PasswordReset from "./pages/PasswordReset";
import PasswordResetConfirm from "./pages/PasswordResetConfirm";
import Account from "./pages/Account";
import Error from "./pages/Error";
import Interfaces from "./pages/Interfaces";
import Tenant from "./pages/Tenant";
import MainLayout from "./components/MainLayout";
import About from "./pages/About";
import InterfaceCreate from "./pages/InterfaceCreate";
import Exports from "./pages/Exports";

import { Role } from "./types";

import { UserProvider } from "./context/UserContext";
import { ToastProvider } from "./context/ToastContext";

import "./App.css";
import ExportsEdit from "./pages/ExportsEdit";

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <UserProvider>
                <ToastProvider>
                    <Routes>
                        <Route path="/" element={<Navigate replace to="/content-creator" />} />

                        {/* Authentication Routes */}
                        <Route path="/" element={<AuthenticationLayout />}>
                            <Route path="login" element={<Login />} />
                            <Route path="password-reset" element={<PasswordReset />} />
                            <Route path="password-reset/confirm" element={<PasswordResetConfirm />} />
                        </Route>

                        {/* Authenticated User Routes */}
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute minimumRole={Role.User}>
                                    <MainLayout />
                                </ProtectedRoute>
                            }
                        >
                            <Route path="content-creator" element={<ContentCreator />} />
                            <Route path="content-converter" element={<ContentConverter />} />
                            <Route path="knowledgebases" element={<KnowledgeBases />} />
                            <Route path="knowledgebases/create" element={<KnowledgeBasesCreateEdit action="create" />} />
                            <Route path="knowledgebases/edit/:id" element={<KnowledgeBasesCreateEdit action="edit" />} />
                            <Route path="workflows" element={<Workflows />} />
                            <Route path="workflows/create" element={<WorkflowCreateEdit action="create" />} />
                            <Route path="workflows/edit/:id" element={<WorkflowCreateEdit action="edit" />} />
                            <Route path="archives" element={<Archives />} />
                            <Route path="archives/edit/:id" element={<ArchivesEdit />} />
                            <Route path="interfaces" element={<Interfaces />} />
                            <Route path="interfaces/create" element={<InterfaceCreate />} />
                            <Route path="exports" element={<Exports />} />
                            <Route path="exports/edit/:id" element={<ExportsEdit />} />
                            <Route path="tenant" element={<Tenant />} />
                            <Route path="account" element={<Account />} />
                            <Route path="about" element={<About />} />
                        </Route>

                        {/* Tenant Admin Routes */}
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute minimumRole={Role.TenantAdmin}>
                                    <MainLayout />
                                </ProtectedRoute>
                            }
                        >
                            <Route path="tenants/edit/:id" element={<TenantCreateEdit action="edit" />} />
                            <Route path="users" element={<Users />} />
                            <Route path="users/create" element={<UserCreateEdit action="create" />} />
                            <Route path="users/edit/:id" element={<UserCreateEdit action="edit" />} />
                        </Route>

                        {/* Super Admin Routes */}
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute minimumRole={Role.SuperAdmin}>
                                    <MainLayout />
                                </ProtectedRoute>
                            }
                        >
                            <Route path="settings" element={<Settings />} />
                            <Route path="tenants" element={<Tenants />} />
                            <Route path="tenants/create" element={<TenantCreateEdit action="create" />} />
                        </Route>

                        {/* Fallback */}
                        <Route path="*" element={<Error />} />
                    </Routes>
                </ToastProvider>
            </UserProvider>
        </BrowserRouter>
    );
};

export default App;