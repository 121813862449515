import React, {ReactNode, useContext, useEffect} from 'react';
import { UserContext } from "../context/UserContext";
import {Role} from "../types";
import {useNavigate} from "react-router-dom";
import {getCurrentUser, getToken, getUser} from "../utils/authentication";

interface ProtectedRouteProps {
    children?: ReactNode;
    minimumRole: Role;
}

// @ts-ignore
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({children, minimumRole}) => {
    const navigate = useNavigate();

    const convertRoleToNumber = (role?: Role) => {
        switch (role) {
            case Role.SuperAdmin:
                return 3;
            case Role.TenantAdmin:
                return 2;
            case Role.User:
                return 1;
            default:
                return 0;
        }
    };

    const { currentUser } = useContext(UserContext);

    const getUserFromStore = async () => {
        const user = await getCurrentUser();
        if (!getToken() || !user) {
            navigate('/login');
        }
    };

    useEffect(() => {
        getUserFromStore();
    }, []);

    if (convertRoleToNumber(currentUser?.role) >= convertRoleToNumber(minimumRole)) {
        return children;
    } else {
        /*
        return (
            <div>
                <div className="h-screen flex flex-col items-center justify-center space-y-8">
                    <h1>Du hast keine Berechtigung!</h1>
                </div>
            </div>
        );
         */
        return;
    }
};

export default ProtectedRoute;
